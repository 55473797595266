<template>
    <div><el-divider></el-divider>
        <el-row :gutter="10">
            <el-col :span="6">
                <el-input placeholder="请输入序列号" v-model="searchObj.onlyText" @change="getList()"><el-button slot="append" icon="el-icon-search" @click="getList()"></el-button></el-input>
            </el-col>
            <el-col :span="3">
                <el-button type="success" @click="setList()">导出PDF</el-button>
            </el-col>
            <el-col :span="3">
                <el-button type="primary" @click="List()">查看图片</el-button>
            </el-col>
        </el-row>
        <el-row style="margin-top: 10px;">
            <el-col :span="15">
                <vxe-table ref="table1" border show-footer show-overflow :data="tableData" :loading="loading" :loading-config="{ icon: 'vxe-icon-indicator roll', text: '正在拼命加载中...' }" :checkbox-config="{ reserve: true, checkRowKeys: defaultSelecteRows }" :row-config="{ isCurrent: true, isHover: true, keyField: 'id', highlight: true }" :cell-style="cellStyle" @cell-click="cellClickEvent">
                    <vxe-column type="expand" width="45" align="center">
                        <template #content="{ row }">
                            <el-descriptions :column="3" border>
                                <el-descriptions-item label="路面类型">{{ row.luMianLeiXing }}</el-descriptions-item>
                                <el-descriptions-item label="路面厚度">{{ row.luMianHouDu }}mm</el-descriptions-item>
                                <el-descriptions-item label="检测单位">{{ row.jianCeDanWei }}</el-descriptions-item>
                                <el-descriptions-item label="检测员">{{ row.jianCeYuan }}</el-descriptions-item>
                                <el-descriptions-item label="经度">{{ row.longitude }}</el-descriptions-item>
                                <el-descriptions-item label="纬度">{{ row.latitude }}</el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </vxe-column>
                    <vxe-column type="checkbox" width="45" fixed="left"></vxe-column>
                    <vxe-column field="serialNumber" title="序列号" width="90px"></vxe-column>
                    <vxe-column field="testTime" title="试验时间" width="150px"></vxe-column>
                    <vxe-column field="shiYanBianHao" title="试验编号" width="80" align="right"></vxe-column>
                    <vxe-column field="shiYanFangShi" title="试验方式" width="80"></vxe-column>
                    <vxe-column field="YaShiDu" title="压实度" align="right" :formatter="formatterNumYaShiDu"></vxe-column>
                    <vxe-column field="strMiDu" title="平均密度" align="right" :formatter="formatterNumstrMiDu"></vxe-column>
                    <vxe-column field="mtd" title="最大理论密度" :formatter="formatterNummtd" align="right"></vxe-column>
                </vxe-table>
                <vxe-pager align="left" @page-change="getList()" :page-size.sync="searchObj.pageSize" :current-page.sync="searchObj.currentPage" :total="searchObj.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total']">
                </vxe-pager>
            </el-col>
            <el-col :span="9">
                <el-tabs type="border-card">
                    <el-tab-pane label="Map">
                        <span slot="label"><i class="el-icon-map-location"></i>地图</span>
                        <div id="mapForWHMD01" style="width:100%;height:400px"></div>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <el-col :span="8">
            <el-dialog title="试验图片" :visible.sync="dialogVisible" width="30%">
                <span>
                    <div id="myModal" class="modal">
                        <el-image id='img_ZhaoPian' :src="imageData" style='width:100%;height:100%;'>
                        </el-image>
                    </div>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
                </span>
            </el-dialog>
        </el-col>
    </div>
</template>
<script>
import XEUtils from "xe-utils";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
    data() {
        return {
            searchObj: {
                onlyText: "",
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            loading: false,
            tableData: [],
            imageData: "",
            dialogVisible: false,
            defaultSelecteRows: [999],
            currentRow: {},
            marker: null,
            map: null,
            AMap: null,
            center: [121.32093057, 31.90087405],
        };
    },

    mounted() {
        let that = this;
        that.getList();
        AMapLoader.load({ key: "d2234c0fa737a6010ec0e3480426e0c3" }).then(
            (AMap) => {
                that.AMap = AMap;
                that.map = new AMap.Map("mapForWHMD01", {
                    center: that.center,
                });
                that.marker = new AMap.Marker({
                    position: that.center,
                });
                that.map.add(that.marker);
            }
        );
    },
    methods: {
        cellClickEvent({ row }) {
            let that = this;
            this.center = [];
            this.center = this.center.concat(
                parseFloat(row.longitude),
                parseFloat(row.latitude)
            );

            AMapLoader.load({ key: "d2234c0fa737a6010ec0e3480426e0c3" }).then(
                (AMap) => {
                    that.AMap = AMap;
                    that.map = new AMap.Map("mapForWHMD01", {
                        center: that.center,
                    });
                    that.marker = new AMap.Marker({
                        position: that.center,
                    });
                    that.map.add(that.marker);
                }
            );
        },
        sumNum(list, field) {
            let count = 0;
            list.forEach((item) => {
                count += Number(item[field]);
            });
            return count;
        },
        cellStyle({ row, column }) {
            if (column.field === "strMiDu") {
                if (row.strMiDu.length >= "0") {
                    return {
                        backgroundColor: "#409EFF",
                    };
                }
            } else if (column.field === "YaShiDu") {
                return {
                    backgroundColor: "#67C23A",
                };
            }
        },
        List() {
            let that = this;
            let selectRecords1 = that.$refs.table1.getCheckboxRecords();
            if (selectRecords1.length == 0) {
                that.$alert("请选中");
            } else {
                that.axios
                    .post("WHMD01/GetPic", { onlyInt: selectRecords1[0].id })
                    .then(function (response) {
                        if (response.data.data) {
                            that.imageData = "data:image/png;base64," + response.data.data;
                            that.dialogVisible = true;
                        } else {
                            that.$alert("无图片");
                        }
                    });
            }
        },
        setList() {
            let that = this;
            let selectRecords = that.$refs.table1.getCheckboxRecords();
            //获取选中行
            if (selectRecords.length == 0) {
                that.$alert("请选中");
            } else {
                let ids = "";
                for (var i = 0; i < selectRecords.length; i++) {
                    ids += selectRecords[i].id + ",";
                }
                open(process.env.VUE_APP_TurnUrl + "WHMD01/Print?Ids=" + ids);
            }
        },
        getList() {
            let that = this;
            that.loading = true;
            that.axios
                .post("WHMD01/GetList", {
                    currentPage: that.searchObj.currentPage,
                    pageSize: that.searchObj.pageSize,
                })
                .then((response) => {
                    that.loading = false;
                    that.tableData = response.data.data.data;
                    that.searchObj.total = response.data.data.total;
                });
        },
        formatterNumYaShiDu({ row }) {
            const arr = row.strMiDu.split(",");
            var sum = 0,
                count = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i]) {
                    sum += parseFloat(arr[i]);
                    count++;
                }
                return (
                    XEUtils.commafy(XEUtils.toNumber((sum / count / row.mtd) * 100), {
                        digits: 2,
                    }) + "%"
                );
            }
        },

        formatterNummtd({ cellValue }) {
            return cellValue + "g/cm³";
        },

        formatterNumstrMiDu({ cellValue }) {
            var arrstrMiDu = cellValue.split(",");
            var sum = 0,
                count = 0;
            for (var i = 0; i < arrstrMiDu.length; i++) {
                if (arrstrMiDu[i]) {
                    sum += parseFloat(arrstrMiDu[i]);
                    count++;
                }
            }

            return (
                XEUtils.commafy(XEUtils.toNumber(sum / count), { digits: 2 }) + "g/cm³"
            );
        },
    },
};
</script>

<style scoped></style>
